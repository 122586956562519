html, body, #root
  font-family: Ubuntu, sans-serif

#root
  width: 100%
  height: 100%

$color-primary: #FEA385
$color-secondary: #FFD4A7
$color-font: #444953
$color-placeholder: #D3D2DD
$color-border: #D3D2DD
$color-border-hover: #A0A1A2
$color-radio-border: #BFBECE
$color-success-green: #94E597
$color-error-red: #D84315
$color-danger: #EF2424
$color-menu-item: #A4502C
$color-background: #F5F7FB
$color-challenge-font: #3E2022

$color-light: #FFFFFF
$color-gray: #ababab
$color-dark-gray: #444953
$color-empty: #e6e5f0
$color-footer-background: #525968
$color-label: #525968
$color-label-light: #8A909D
$color-gray-label: #646D80
$color-calendar-border: #E1E0ED
$color-success-green-1: #79D17C
$color-progress-bar: #EDEDEE
$color-tag: #F8F8F8
$color-border-active: #ffc5ad

$gradient-primary: linear-gradient(88deg, #E69175 35%, #FFD4A7 124%)
$gradient-modal: linear-gradient(66deg, #ECA790 39%, #F1C69A 120%)
$gradient-budge: linear-gradient(90deg, #ECA790 0%, #F1C69A 100%)

.site-layout-background
  background: #fff

.app-logo
  height: 48px
  background-image: url('../assets/images/logos/img.png')
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  margin: 8px
  margin-bottom: 30px

.flex
  display: flex

.inline-flex
  display: inline-flex

.flex, .inline-flex
  &.direction-row
    flex-direction: row

  &.direction-col
    flex-direction: column

  &.justify-center
    justify-content: center

  &.justify-start
    justify-content: flex-start

  &.justify-end
    justify-content: flex-end

  &.justify-between
    justify-content: space-between

  &.align-start
    align-items: start

  &.align-center
    align-items: center

  &.align-end
    align-items: end

  &.wrap
    flex-flow: wrap

.ant-table-cell
  cursor: default

  .note
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis

.general-table
  &.overflow-x-scroll
    overflow-x: auto

  margin-top: 15px

  tr
    td
      cursor: pointer

  &.promo-codes-usage
    tr
      td
        cursor: default


label.general-label
  font-weight: bold

.flex-container
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  width: 100%
  height: 100%

.login-logo
  height: 80px
  background-image: url('../assets/images/logos/img.png')
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  margin-bottom: 30px

.profile-dropdown-item
  margin-right: 20px
  cursor: pointer

.upload-field
  width: 100px
  height: 100px

  img
    max-width: 100px
    max-height: 100px

  &.upload-with-remove
    .ant-upload
      position: relative

      .upload-remove-button
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        font-size: 16px
        top: 4px
        right: 3px
        width: 26px
        min-width: 26px
        height: 26px
        color: $color-light
        background-color: rgba($color-gray, 0.6)
        border-radius: 50%
        transition: all .2s ease-in-out

        &:hover
          background-color: $color-danger

.content-box
  padding: 20px 0 10px 0

.tooltip-error
  display: block
  color: red

input, textarea
  &:required, &:invalid
    box-shadow: none

.input-wrapper
  margin-bottom: 10px

  label
    font-weight: bold

    &.required:after
      color: $color-error-red
      content: " *"

    .counter
      background-color: $color-primary
      color: white
      padding: 2px 10px
      font-weight: normal
      border-radius: 10px

  .disabled
    border: none

  .action-buttons
    margin: 10px 0
    width: 100%
    display: flex
    align-items: center
    justify-content: flex-end

  .circle-picker
    width: 100% !important

  .chrome-picker
    width: 100% !important

.ant-transfer-list-header-dropdown
  display: none

.ant-picker
  width: 100%

.ant-input
  &:disabled
    color: rgba(0, 0, 0, 0.65)
    background-color: white
    padding-left: 0

.page-wrapper
  .avatar-uploader > .ant-upload
    max-width: 128px
    max-height: 128px

  img.upload-image
    width: 100%
    max-width: 84px
    max-height: 84px
    background-position: center
    background-repeat: no-repeat
    background-size: contain

  .translations
    margin-top: 10px
    overflow: visible

  .grid

    .main-content
      background-color: white
      padding: 20px

    .sidebar-content
      background-color: white
      padding: 20px

      .ant-btn
        margin: 10px 0 !important

      .ant-tooltip-disabled-compatible-wrapper .ant-btn
        width: 100%

  .landing-section
    margin-bottom: 40px

.not-found-page
  .image-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .not-found-image
      height: 400px
      width: 550px
      background: center no-repeat

    .title
      font-family: Poppins
      font-size: 40px
      margin-bottom: 40px


    .redirect-button
      margin-bottom: 40px


.page-builder
  margin-top: 20px
  padding: 20px
  background-color: white

.grecaptcha-badge
  visibility: hidden

.recaptcha-terms
  p
    font-size: 11px
    line-height: 130%
    color: $color-font
    opacity: 0.8

    span
      font-weight: 300

    a
      font-weight: 300
      color: $color-primary
      text-decoration: underline

      &:hover
        color: $color-primary !important
        opacity: 0.8


.ant-table-cell
  img.logo
    height: 30px
    width: auto

  &.thumbnail-column
    width: 100px

    img.thumbnail-image
      width: 100%

.dashboard-card
  margin-bottom: 20px
  background-color: white
  padding: 20px

  .card-content
    display: flex
    justify-content: space-between
    flex-wrap: wrap

.row-dragging
  background: #fafafa
  border: 1px solid #ccc


.row-dragging td
  padding: 16px
  visibility: hidden

.row-dragging .drag-visible
  visibility: visible

.detail-buttons
  .ant-btn
    margin: 0.32em 15px 0.32em 0

    &:last-child
      margin-right: 0

.draggable-table-field
  tr.drop-over-downward
    td
      border-bottom: 2px dashed $color-primary

  tr.drop-over-upward
    td
      border-top: 2px dashed $color-primary

.wysiwyg
  a
    cursor: text
    color: $color-primary

.wysiwyg-editor
  h1
    margin: 0 0 0.6em

  p
    margin: 0

  ul, ol
    margin: 0.6em 0
    padding-inline-start: 40px

  li
    margin: 0.6em 0


.wysiwyg-editor-input-field
  transition: all 0.2s

.wysiwyg-editor-input-field .css-t12sj4
  margin: 0 !important
  padding: 17px 18px
  border: 1px solid #d9d9d9
  border-radius: 2px 2px 0px 0px
  border-bottom: 2px solid rgba(#d9d9d9, 0.4)
  transition: all 0.2s

.wysiwyg-editor-input-field .css-t12sj4 + div
  padding: 30px 36px
  min-height: 120px
  border: 1px solid #d9d9d9
  border-top: none
  border-radius: 0px 0px 2px 2px
  transition: all 0.2s

.wysiwyg-editor-input-field.active
  box-shadow: 0 0 7px -1px $color-border-active

.wysiwyg-editor-input-field:hover .css-t12sj4, .wysiwyg-editor-input-field:hover .css-t12sj4 + div
  border-color: $color-border-active

.wysiwyg-editor-input-field:hover .css-t12sj4
  border-bottom-color: rgba($color-border-active, 0.25)

.wysiwyg-editor-input-field.active .css-t12sj4, .wysiwyg-editor-input-field.active .css-t12sj4 + div
  border-color: $color-border-active

.wysiwyg-editor-input-field.active .css-t12sj4
  border-bottom-color: rgba($color-border-active, 0.25)

.no-challenge-notification
  font-size: 16px
  min-height: 63.2px
  margin: 0
  color: rgba(0, 0, 0, 0.45)

.table-select-input-wrapper
  margin-bottom: 0

.fs-arrow-button
  display: flex
  width: 32px
  height: 15px
  padding: 0
  margin: 0
  justify-content: center
  align-items: center
  overflow: hidden

  .anticon
    font-size: 13px
    margin-top: 1px

.category-item
  display: flex
  align-items: center

.drag-handle
  cursor: move
  color: #999
  width: 30px
  margin-right: 30px

.true-cell, .false-cell
  display: inline-block
  width: 10px
  height: 10px
  border-radius: 50%

.true-cell
  background-color: $color-success-green-1

.false-cell
  background-color: $color-border

.exercise-list-table
  .col-exercise-name
    width: 30%

.inverse-logo-uploader
  .ant-upload
    color: $color-placeholder
    background-color: $color-dark-gray
    border-color: $color-placeholder

.ant-table-row.disabled-table-row > td:hover
  cursor: not-allowed

.socials-label
  display: block
  margin-bottom: 8px

.socials-icons > span
  margin-right: 7px

.divider
  &:before
    width: 0 !important

  .ant-divider-inner-text
    padding-left: 0

  &.ant-divider-horizontal,
  &.ant-divider-horizontal.ant-divider-with-text
    margin: 8px 0 !important

.disabled-input-darker
  .ant-picker-input > input[disabled]
    color: rgba(0, 0, 0, 0.5)
    
.qrCode-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

@media (max-width: 1275px)
  .exercise-list-table
    .col-exercise-name
      width: 24%
